<template>
  <div class="login" :style="'height: ' + height + 'px;'">
    <AdminLoginForm :type="'static'"></AdminLoginForm>
  </div>
</template>

<script>
import AdminLoginForm from "@/components/Login/AdminLoginForm";

var glob;

export default {
  name: "Login_Page",
  components: {
    AdminLoginForm
  },
  data() {
    return {
      height: 0
    };
  },
  mounted() {
    glob = this;
    this.height = window.innerHeight;

    window.onresize = () => {
      glob.height = window.innerHeight;
    };
  },
  methods: {}
};
</script>

<style lang="scss">
.login {
  @include flex(row, center, center);
  width: 100vw;

  @media #{$breakpoint-down-sm} {
    align-items: center;
    overflow-y: auto;
  }
}
</style>
